import { initializeApp } from 'firebase/app';
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import { getRemoteConfig, fetchAndActivate, getAll } from "firebase/remote-config";
import { getAnalytics, setUserProperties } from "firebase/analytics"

import * as braze from '@braze/web-sdk'
import amplitude from 'amplitude-js'

import {
  AMPLITUDE_API_KEY,
  BRAZE_API_KEY,
  ENVIRONMENT,
  FIREBASE_API_KEY, FIREBASE_APP_ID, FIREBASE_BUCKED,
  FIREBASE_DOMAIN, FIREBASE_ID, FIREBASE_MEASURRING, FIREBASE_PROJECT,
  FIREBASE_URL,
  GOOGLE_OPTIMIZE,
  GOOGLE_TAG_MANAGER,
  VERSION
} from "../config";

async function sha256(value) {
  if (!value) return '';
  const msgBuffer = new TextEncoder('utf-8').encode(value);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
  return hashHex;
}

export function createDataLayer (user) {
  sha256(user.email).then((emailHash) => {
    const dataLayer =  {
      userId: user.id || '',
      email: user.email || '',
      emailHash,
      levelSelected: (user.level && user.level.id) || ''
    }

    if (window.dataLayer && window.dataLayer.length > 0 && window.dataLayer[0].userId === user.id) {
      window.dataLayer[0] = dataLayer
    } else {
      window.dataLayer = [dataLayer]
    }
  })
}

export async function addGoogleOptimize() {
  let head = document.getElementsByTagName("head")[0];
  let script = document.createElement("script");
  script.src=`https://www.googleoptimize.com/optimize.js?id=${GOOGLE_OPTIMIZE}`;
  script.defer = true;
  head.appendChild(script);
}

export async function addGoogleTagManagerHead() {
  let head = document.getElementsByTagName("head")[0];
  let script = document.createElement("script");
  script.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER}');`;
  script.defer = true;
  head.appendChild(script);
}

export async function addGoogleTagManagerBody() {
  let script = document.createElement("noscript");
  script.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_MANAGER}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
  document.body.appendChild(script);
}

export async function addGoogleAnalytics() {
  let head = document.getElementsByTagName("head")[0];
  let script_tag = document.createElement("script");
  script_tag.defer = true;
  let script = document.createElement('script')
  script.defer = true;

  switch (ENVIRONMENT) {
    case 'sandbox':
      script_tag.setAttribute('async', '')
      script_tag.src = 'https://www.googletagmanager.com/gtag/js?id=UA-1201726-17'

      head.appendChild(script_tag)

      script.text = `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'UA-1201726-17');`
      head.appendChild(script)
      break

    case 'production':
      script_tag.setAttribute('async', '')
      script_tag.src = 'https://www.googletagmanager.com/gtag/js?id=UA-1201726-16'

      head.appendChild(script_tag)

      script.text = `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'UA-1201726-16');`

      head.appendChild(script)
      break

    default:
      return ''
  }
}

export function inicializeFirebase () {
  const firebaseConfig = {
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_DOMAIN,
    databaseURL: FIREBASE_URL,
    projectId: FIREBASE_PROJECT,
    storageBucket: FIREBASE_BUCKED,
    messagingSenderId: FIREBASE_ID,
    appId: FIREBASE_APP_ID,
    measurementId: FIREBASE_MEASURRING
  }

  return initializeApp(firebaseConfig);
}

export function setFirebaseProperties (app, user) {
  const analytics = getAnalytics(app)

  const userTypeString = user.userType ===  '2' ? 'Premium' : 'Free'

  setUserProperties(analytics, {
    id_user: user.id,
    user_level: (user.level && user.level.id),
    user_lang_id: user.language,
    user_country_id: user.country,
    user_type: userTypeString,
    user_expiration_date: user.expirationDate,
    user_entry_date: user.entryDate || ''
  })
}

export async function getFirebaseVariant () {
  let returnValue = {}
  try {
    const remoteConfig = getRemoteConfig()
    remoteConfig.settings.minimumFetchIntervalMillis = 0
    await fetchAndActivate(remoteConfig)
    const allConfigItems = getAll(remoteConfig)
    Object.entries(allConfigItems).forEach(([key, value]) => {
      if (key.startsWith('firebase_web')) {
        returnValue[key] = value
      }
    })
  } catch (err) {
    console.log(err + ' Remote Config')
  }
  return returnValue
}

const clearBrazeLocalStorage = () => {
  const localStorageKeys = Object.keys(localStorage).filter((key) => key.startsWith('ab.storage.'))
  localStorageKeys.forEach((key) => {
    localStorage.removeItem(key)
  })
}

export async function InicializeBraze (user) {
  // initialize the SDK
  clearBrazeLocalStorage()
  
  braze.initialize(BRAZE_API_KEY, {
    safariWebsitePushId: 'web.com.abaenglish.academy',
    baseUrl: 'https://sdk.fra-01.braze.eu/api/v3',
    minimumIntervalBetweenTriggerActionsInSeconds: 1,
    allowUserSuppliedJavascript: true
  })

  braze.subscribeToInAppMessage((inAppMessage) => {
    let shouldDisplay = true

    if (inAppMessage instanceof braze.InAppMessage) {
      const msgId = inAppMessage.extras['msg-id']

      if (msgId === 'push-primer') {
        if (!braze.isPushSupported() || braze.isPushPermissionGranted() || braze.isPushBlocked()) {
          shouldDisplay = false
        }

        if (inAppMessage.buttons[0] !== null) {
          inAppMessage.buttons[0].subscribeToClickedEvent(function () {
            braze.requestPushPermission()
          })
        }
      }
    }

    if (shouldDisplay) {
      braze.showInAppMessage(inAppMessage)
    }
  })

  braze.openSession();

  braze.changeUser(user.id)
  braze.getUser().addAlias(user.id, 'internal_id')
  braze.getUser().setEmail(user.email)
  braze.getUser().setFirstName(user.name)
  braze.getUser().setLastName(user.surname)
  braze.getUser().setLanguage(user.language)

  const resetGlobalRateLimit = () => {
    const localStorageKeys = Object.keys(localStorage).filter((key) => key.startsWith('ab.storage.globalRateLimitCurrentTokenCount'))
    localStorageKeys.forEach((key) => {
      const rateLimit = JSON.parse(localStorage.getItem(key))
      if (rateLimit.v <= 10) {
        localStorage.setItem(key, JSON.stringify({ v: 28 }))
      }
    })
  }

  window.appboy = {
    logCustomEvent: (eventName, eventProperties) => {
      resetGlobalRateLimit()
      return braze.logCustomEvent(eventName, eventProperties)
    },
    subscribeToContentCardsUpdates: (subscriber) => {
      resetGlobalRateLimit()
      return braze.subscribeToContentCardsUpdates(subscriber)
    },
    requestContentCardsRefresh: (successCallback, errorCallback) => {
      resetGlobalRateLimit()
      braze.requestContentCardsRefresh(successCallback, errorCallback)
    },
    getUser: () => {
      resetGlobalRateLimit()
      return braze.getUser()
    }
  }
}

export function RequestPushPermissionBraze () {
  braze.requestPushPermission()
}

function getUserType (permissions) {
  if (!permissions || permissions.length === 0) return 'free'
  if (permissions.length === 1 && permissions[0] === 'academy') return 'basic'
  if (permissions.length === 2 && permissions.includes('academy') && permissions.includes('global_exam')) return 'basic'
  if (permissions.length > 1 && permissions.includes('academy') && permissions.includes('group_class')) return 'premium'
  return 'not_found'
}

export async function InicializeAmplitude (user) {
  const amplitudeInstance = amplitude.getInstance()
  amplitudeInstance.init(AMPLITUDE_API_KEY)
  window.amplitude = amplitudeInstance

  const userProperties = {
    user_lang: user.language,
    acquisition_partner_id: user.acquisitionPartnerId,
    user_level: user.level && user.level.text,
    last_login_date: user.lastLoginTime,
    source_id: user.sourceId,
    user_type: getUserType(user.permissions),
    user_type_permissions: user.permissions,
    version: VERSION,
    is_academy: user.isAcademy || false,
  }
  amplitudeInstance.setUserId(user.id)
  amplitudeInstance.setUserProperties(userProperties)
}
