export function getTranslation(translations, key) {
    const thereIsKey = translations && translations[key] instanceof Object
    const phrase = thereIsKey && translations[key].message
    return phrase || key
}

export function hasUrlParameter (paramName) {
  if (typeof URLSearchParams !== 'undefined') {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.searchParams)
    return params.has(paramName)
  }
}

export function getUrlParameter (paramName) {
  const url = new URL(window.location.href)
  return url.searchParams.get(paramName)
}

export function removeUrlParameters (paramNames) {
  if (typeof URLSearchParams !== 'undefined') {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.searchParams)
    const foundParamNames = paramNames && paramNames.filter(paramName => params.has(paramName))
    if (foundParamNames && foundParamNames.length > 0) {
      foundParamNames.forEach(paramName => {
        params.delete(paramName)
      })
      const newQuery = params.toString()
      const newPath = `${url.pathname}${newQuery ? `?${newQuery}` : ''}`
      window.history.replaceState({}, document.title, newPath)
    }
  }
}

function styleLoaded (styleUrl) {
  const documentStyleSheets = Array.from(document.styleSheets || document.querySelectorAll('link[rel="stylesheet"]'))
  return documentStyleSheets.some(link => link.href === styleUrl)
}

function waitForCss(styleUrl, callback, starttime)
{  
  var timeout = 10000;
  if (styleLoaded(styleUrl) || (new Date().getTime())-starttime>timeout)
    callback();
  else {
    window.setTimeout(function () { waitForCss(styleUrl, callback, starttime) }, 50);
  }
}

async function addCssToHead (key, css, callback) {
  let stylesHref = `data:text/css,${escape(`@import url('${css}');`)}`;
  if (!styleLoaded(css)) {
    if (document.createStyleSheet) {
      document.createStyleSheet(css);
    } else {
      let newSS = document.createElement("link");
      newSS.rel = "stylesheet";
      newSS.href = stylesHref;
      newSS.id = `style-${key}`;
      document.getElementsByTagName("head")[0].appendChild(newSS);
      waitForCss(stylesHref, callback, new Date().getTime())
    }
  } else {
    callback();
  }
}

export async function addDependencyToHead (key, css, src, callback) {
  addCssToHead(key, css, () => {
    let head = document.getElementsByTagName("head")[0];
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.id = `script-${key}`
    if (callback) {
      script.onload = callback;
    }
    script.src = src;
    head.appendChild(script);
  });
}

export function readCookie () {
    let cookie
    let list = document.cookie.split(";");
    for (let i in list) {
      let search = list[i].search('eal');
      if (search > -1) {
        cookie=list[i]
      }
    }
    let objectCookie
    if (cookie)
    {
      let [, value] = cookie.split('=')
      objectCookie = JSON.parse(decodeURIComponent(value))
    }
    return objectCookie;
}
  
  export function removeUnusedDependenciesFromHead (key) {
    let scripts = document.querySelectorAll(`script[id='script-${key}']`);
    scripts.forEach(function(item, index) {
      item.parentNode.removeChild(item);
    })
  
    if (document.createStyleSheet && document.removeImport) {
      for (var i = 0; i < document.styleSheets.length; i++) {
        const href = document.styleSheets[i].href;
        if (href.indexOf('@import')>=0 && href.indexOf('main.css')>=0) {
          document.removeImport(i);
        }
      }
    } else {
      let scripts = document.querySelectorAll(`link[id='style-${key}']`);
      scripts.forEach(function(item, index) {
        item.parentNode.removeChild(item);
      })
    }
}
