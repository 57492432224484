export const microfrontends = 
[
  {
    "key": "academy-modals",
    "version": "0.3.0",
    "name": "academyMicroFrontendModals"
  },
  {
    "key": "academy-leftbar",
    "version": "0.1.1",
    "name": "academyMicroFrontendLeftBar"
  },
  {
    "key": "academy-topbar",
    "version": "0.1.5",
    "name": "academyMicroFrontendTopBar"
  },
  {
    "key": "academy-timelinehome",
    "version": "0.6.0",
    "name": "academyMicroFrontendTimeLine",
    "routes": [
      {
        "path": "/",
        "leftbar": true,
        "topbar": true,
        "showSplashScreen": true
      }
    ]
  },
  {
    "key": "academy-migrate",
    "version": "1.0.0",
    "name": "academyMigrateMicroFrontend",
    "routes": [
      {
        "path": "/migrate"
      }
    ]
  },
  {
    "key": "academy-home",
    "version": "1.4.0",
    "name": "academyMicroFrontend",
    "routes": [
      {
        "path": "/unit/:unitId",
        "showSplashScreen": true
      },
      {
        "path": "/level/:levelId/unit/:unitId",
        "showSplashScreen": true
      },
      {
        "path": "/unit/:unitId/activity/:activityId",
        "showSplashScreen": true
      },
      {
        "path": "/level/:levelId/unit/:unitId/activity/:activityId",
        "showSplashScreen": true
      },
      {
        "path": "/logout"
      }
    ]
  },
  {
    "key": "academy-microlessons",
    "version": "0.2.0",
    "name": "academyMicroFrontendMicrolessons",
    "routes": [
      {
        "path": "/microlessons",
        "leftbar": true,
        "topbar": true,
        "showSplashScreen": true
      },
      {
        "path": "/microlessons/:microlessonId",
        "leftbar": true,
        "topbar": true,
        "showSplashScreen": true
      },
      {
        "path": "/microlessons/filters/:filter",
        "leftbar": true,
        "topbar": true,
        "showSplashScreen": true
      }
    ]
  },
  {
    "key": "academy-certificates",
    "version": "0.4.0",
    "name": "academyMicroFrontendCertificates",
    "routes": [
      {
        "path": "/certificate",
        "leftbar": true,
        "topbar": true,
        "showSplashScreen": true
      }
    ]
  },
  {
    "key": "academy-onboarding",
    "version": "1.2.0",
    "name": "onBoardingMicroFrontend",
    "routes": [
      {
        "path": "/onboarding"
      },
      {
        "path": "/onboarding/motivation"
      },
      {
        "path": "/onboarding/level"
      },
      {
        "path": "/onboarding/result"
      },
      {
        "path": "/onboarding/discover-goals"
      },
      {
        "path": "/onboarding/discover-extra-goals"
      },
      {
        "path": "/onboarding/offer"
      }
    ]
  },
  {
    "key": "academy-abalive",
    "version": "0.3.1",
    "name": "abaLiveMicroFrontend",
    "routes": [
      {
        "path": "/abalive",
        "leftbar": true,
        "topbar": true,
        "showSplashScreen": true
      }
    ]
  },
  {
    "key": "academy-promocodes",
    "version": "0.0.15",
    "name": "promocodesMicroFrontend",
    "routes": [
      {
        "path": "/promocodes"
      }
    ]
  },
  {
    "key": "academy-virtualteacher",
    "version": "0.2.0",
    "name": "academyMicroFrontendVirtualTeacher",
    "routes": [
      {
        "path": "/virtualteacher",
        "leftbar": true,
        "topbar": true,
        "showSplashScreen": true
      }
    ]
  },
  {
    "key": "academy-videoclasses",
    "version": "0.0.45",
    "name": "academyMicroFrontendVideoClasses",
    "routes": [
      {
        "path": "/videoclasses",
        "leftbar": true,
        "topbar": true,
        "showSplashScreen": true
      }
    ]
  },
  {
    "key": "academy-payment",
    "version": "0.10.0",
    "name": "academyMicroFrontendPayments",
    "routes": [
      {
        "path": "/plans"
      },
      {
        "path": "/checkout"
      },
      {
        "path": "/renewal"
      }
    ]
  },
  {
    "key": "academy-profile",
    "version": "0.2.0",
    "name": "academyMicroFrontendProfile",
    "routes": [
      {
        "path": "/profile",
        "leftbar": true,
        "topbar": true
      },
      {
        "path": "/password",
        "leftbar": true,
        "topbar": true
      },
      {
        "path": "/resetpassword",
        "leftbar": false,
        "topbar": false
      }
    ]
  }
]
