export const urlsMicrofrontsEnv = (env) => {
  switch (env) {
    case 'production':
      return '.abaenglish.com'
    case 'qa':
      return '-qa.aba.land'
    case 'staging':
      return '-staging.aba.land'
    case 'sandbox':
      return '-sandbox.aba.land'
    default:
      return `-${env}.aba.land`
  }
}