import * as axios from 'axios'

import { AUTHORIZATION_URI, CLIENT_ID, CLIENT_SECRET, GATEWAY_URL, TOKEN_URI } from '../../config'
import { getUrlParameter, removeUrlParameters } from '../../utils'

import { OAuthPass } from 'frontend-oauth-service'
import { microfrontends as fallbackMicrofrontends } from './fallback/microfrontends'
import jwt from 'jwt-decode'
import { parsePermissions } from '../../domain/permissionsDomain';
import { parseUser } from '../../domain/userDomain'
import { readCookie } from "../../utils";

const DEFAULT_LANGUAGE = 'en'
export const DEFAULT_LOCALE = 'en-EN'
const SUPPORTED_LOCALES = ['de-DE', 'en-EN', 'es-ES', 'fr-FR', 'it-IT', 'pt-PT', 'ru-RU', 'tr-TR']

const ACCESS_TOKEN_URL_PARAMETER = 't'
const APIKEY_URL_PARAMETER = 'apiKey'
const USERNAME_URL_PARAMETER = 'username'

function createSessionCookieIfNotExists (user) {
    const cookiePrefix = 'eal'
    let eal = readCookie()
    if (!eal || eal.u.email !== user.email || eal.u.keyExternalLogin !== user.keyExternalLogin) {
      eal = {
        u: {
          email: user.email,
          keyExternalLogin: user.keyExternalLogin,
          acquisitionPartnerId: user.acquisitionPartnerId,
          userType: user.userType,
          fullName: `${user.name} ${user.surname}`,
          countryId: user.countryId,
          language: user.language,
          userId: user.id,
          level: user.level
        },
        c: CLIENT_ID,
        s: CLIENT_SECRET
      }
      document.cookie = `${cookiePrefix}=${encodeURIComponent(JSON.stringify(eal))}`
    }
}

function oauthPassInit(username, password) {
    OAuthPass.init(
        CLIENT_ID,
        CLIENT_SECRET,
        username,
        password,
        TOKEN_URI,
        AUTHORIZATION_URI
    )
}

export async function initializeOauth () {
    const eal = readCookie()
    const userData = (eal && eal.u) || {}
    const jwtAccessToken = getUrlParameter(ACCESS_TOKEN_URL_PARAMETER)
    const apiKey = getUrlParameter(APIKEY_URL_PARAMETER)
    const username = getUrlParameter(USERNAME_URL_PARAMETER)
    const cookieKey = userData.keyExternalLogin
    if (jwtAccessToken) {
        removeUrlParameters([ACCESS_TOKEN_URL_PARAMETER])
        try {
            const deserializedAccessToken = jwt(jwtAccessToken)
            oauthPassInit(deserializedAccessToken.user_name, '')
            await OAuthPass.refreshToken({ refresh_token: jwtAccessToken })
        } catch { }
    } else if (apiKey && username) {
        removeUrlParameters([APIKEY_URL_PARAMETER, USERNAME_URL_PARAMETER])
        oauthPassInit(username, apiKey)
    } else if (cookieKey) {
        const email = userData.email
        oauthPassInit(email, cookieKey)
    } else {
        oauthPassInit('', '')
    }
    return OAuthPass
}

export async function cleanAccessToken() {
    const domain = document.location.hostname
    const resultDomain = domain.substring(domain.indexOf('.') + 1)

    document.cookie = 'eal=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    document.cookie = `eal=; path=/; domain=.${resultDomain}; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    await OAuthPass.cleanStorage()
}

export async function getUser () {
    const authorization = `Bearer ${await OAuthPass.getAccessToken()}`
    const URL = `${GATEWAY_URL}/api/v2/users/me`

    const textLevel = {
        1: 'beginners',
        2: 'lower intermediate',
        3: 'intermediate',
        4: 'upper intermediate',
        5: 'advanced',
        6: 'business'
    }

    let user = {}

    const config = {
        headers: { Authorization: authorization }
    }

    const response = await axios.get(URL, config)
    if (response.status === 200) {
        user = parseUser(response.data)
        user.level = {
            id: response.data.currentLevel,
            text: textLevel[response.data.currentLevel]
        }
    }
    createSessionCookieIfNotExists(user)
    return user
}

export async function getPermissions () {
  const authorization = `Bearer ${await OAuthPass.getAccessToken()}`
  const URL = `${GATEWAY_URL}/v3/users/me`

  let permissions = {}

  const config = {
      headers: { Authorization: authorization }
  }

  const response = await axios.get(URL, config)
  if (response.status === 200) {
    permissions = parsePermissions(response.data)
  }
  return permissions
}

export async function getTranslations(language) {
    const locale = convertLanguageToLocale(language)
    let translations

    const URL = `https://api.phrase.com/v2/projects/f9d405a1d9b34d73d3488f1f7524d0e9/locales/${locale}/download?file_format=json`
    const URL_TRANSLATIONS_S3 = `https://static.abaenglish.com/academy/translations/${locale}.json`
    const config = {
        headers: {
            Authorization: 'token aa14db9ec7ef8a1b95e748d7e42b44972e89d63d9f2ced621f81fb14a8cb0c08'
        },
        timeout: 20000
    }

    try {
        const response = await axios.get(URL, config)
        if (response.status === 200) {
            translations = response.data
        }
    } catch { }

    if (!translations) {
        try {
            const responseS3 = await axios.get(URL_TRANSLATIONS_S3)
            translations = responseS3.data
        } catch { }
    }
    sessionStorage.translations = JSON.stringify(translations);
    sessionStorage.translations_language = language;
    return translations
}

export async function getMicrofrontends() {
    const URL = `https://legacy-requests.abaenglish.com/api/v1/web-microfrontends?v=${(new Date().getTime())}`
    let microfrontends = getFallbackMicrofrontends()
    try {
        const response = await axios.get(URL)
        microfrontends = response.data
    } catch { }
    return microfrontends
}

export function getFallbackMicrofrontends() {
    return fallbackMicrofrontends
}

export function convertLanguageToLocale(language) {
    language = language || DEFAULT_LANGUAGE

    const find = SUPPORTED_LOCALES.find(elem => {
        return elem.startsWith(language)
    })

    return find || DEFAULT_LOCALE
}
