export const parseUser = config => (
  {
    id: config.id,
    name: config.name || '',
    surname: config.surnames || '',
    language: config.language,
    keyExternalLogin: config.autoLogin,
    userType: config.type,
    isPremium: config.type === 2,
    level: config.currentLevel,
    countryId: config.country.id,
    country: config.country ? config.country.iso2 : '',
    acquisitionPartnerId: config.acquisitionPartnerId,
    isAcademy: true,
    comercialNotifications: config.comercialNotifications || false,
    email: config.email,
    entryDate: config.entryDate,
    telephone: config.telephone,
    privateClasses: {}
  }
)
