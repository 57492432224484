window.process = window.process || { env: {} }

export const GATEWAY_URL = process.env.REACT_APP_API_URL
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
export const CAMPUS_URL = process.env.REACT_APP_CAMPUS_URL
export const CLOUD_CONFIG_SERVER = process.env.REACT_APP_CLOUD_CONFIG_SERVER
export const CLIENT_ID = 'frontend-abawebapps'
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET
export const GOOGLE_OPTIMIZE = process.env.REACT_APP_GOOGLE_OPTIMIZE
export const GOOGLE_TAG_MANAGER = process.env.REACT_APP_GOOGLE_TAG_MANAGER
export const BRAZE_API_KEY = process.env.REACT_APP_BRAZE_API_KEY
export const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY
export const VERSION = process.env.REACT_APP_VERSION || 'dev'

/* FIREBASE */
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY
export const FIREBASE_DOMAIN = process.env.REACT_APP_FIREBASE_DOMAIN
export const FIREBASE_URL = process.env.REACT_APP_FIREBASE_URL
export const FIREBASE_PROJECT = process.env.REACT_APP_FIREBASE_PROJECT
export const FIREBASE_BUCKED = process.env.REACT_APP_FIREBASE_BUCKED
export const FIREBASE_ID = process.env.REACT_APP_FIREBASE_ID
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID
export const FIREBASE_MEASURRING = process.env.REACT_APP_FIREBASE_MEASURRING

export const TOKEN_URI = `${GATEWAY_URL}/oauth/token`
export const AUTHORIZATION_URI = `${GATEWAY_URL}/oauth/authorize`

/* EVENTS */
export const CHANGE_LANGUAGE_EVENT = 'CHANGE_LANGUAGE'
export const CHANGE_LEVEL_EVENT = 'CHANGE_LEVEL'
export const LOGOUT_EVENT = 'LOGOUT'
export const REQUEST_PUSH_PERMISSION_EVENT = 'REQUEST_PUSH_PERMISSION'
export const SHOW_MODAL_EVENT = 'SHOW_MODAL'
export const SHOW_MODAL_REQUEST_EVENT = 'SHOW_MODAL_REQUEST'

/* QUERY STRINGS */

export const QUERY_STRING_REDIRECT = 'redirect'

/* LOCAL STORAGE */
export const LOCAL_STORAGE_REDIRECT = 'aba_redirect_url'